<template>
  <div class="selectUser">
    <Layout>
      <Sider hide-trigger>
        <LiefengTree
          height="690"
          :fixedmenu="true"
          treeID="mychild2"
          style="width: 100%;"
          ref="mychild"
          @handleData="getList"
        ></LiefengTree>
      </Sider>
      <Layout>
        <Content style="background:white;border:1px solid rgba(000,000,000,.1);margin-left:20px;">
          <div class="content">
            <div class="tabList">
              <div class="top">
                 <Input
                :maxlength="20"
                enter-button
                v-model.trim="searchData.name"
                placeholder="姓名"
                style="width: 150px;margin:0 0 0 20px"
                @on-enter="getList"
                />
                <Input
                  :maxlength="20"
                  enter-button
                  v-model.trim="searchData.phone"
                  placeholder="手机号"
                  style="width: 200px;margin:0 10px"
                  @on-enter="getList"
                />
                <Button type="primary" icon="ios-search" @click="getList">查询</Button>
                <Button style="margin:0 5px 0 13px" type="success" icon="ios-refresh" @click="rest">重置</Button>
              </div>
             <div class="bottom">
                 <Table
                  ref="selection"
                  :columns="talbeColumns"
                  :data="tableData"
                  @on-select="onSelect"
                  @on-select-cancel="onSelectCancel"
                  @on-select-all="onSelectAll"
                  @on-select-all-cancel="onSelectAllCancel"
                  style="padding-top: 14px"
                ></Table>
             </div>
            
              <Page
                class="page"
                show-total
                @on-change="currentPage"
                @on-page-size-change="hadlePageSize"
                :total="total"
                size="small"
                :page-size.sync="pageSize"
                :current="curPage"
                show-elevator
              />
            </div>
            <div class="buttonList">
              <Button @click="removeAll">清空</Button>
            </div>
            <div class="selectList">
              <Tree :data="sortSelect"></Tree>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script>
import LiefengTree from "@/components/LiefengTree";

export default {
  name: "selectUser",
  components: {
    LiefengTree
  },
  data() {
    return {
      //tree菜单显示与隐藏
      cascaderFocusShow: false,
      //固定菜单标识
      fixedMenu: false,
      status: "",

      selectModal: false,
      total: 0,
      pageSize: 1000,
      curPage: 1,
      selection: [],
      sortSelect: [],
      fruit: [],
      nodeList: [
        // {
        //     value: "New York",
        //     label: "纽约",
        // },
      ],
      nodeId: "",
      searchData: {
        pageSize: 10,
        page: 1,
        name: "",
        phone: ""
      },
      // 树形控件data
      talbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: "序号",
          type: "index",
          width: 80,
          align: "center"
        },
        {
          title: "住房编号",
          key: "houseCode",
          sortable: true,
          minWidth: 150,

          align: "center"
        },
        {
          title: "姓名",
          key: "name",
          sortable: true,
          align: "center",
          width: 100
        },
        {
          title: "性别",
          sortable: true,
          align: "center",
          width: 100,
          render:(h,params) =>{
            return(h('div',{},params.row.sex == 0 ?'未知':params.row.sex == 1 ?'男':params.row.sex == 2 ? '女':''))
          }
        },
        {
          title: "手机号",
          key: "phone",
          sortable: true,
          align: "center",
          minWidth: 100,
        }
      ],
      tableData: [],
      treeData: [],

      // 表单数据
      formLeft: {
        input1: "",
        input2: "",
        input3: ""
      },
      tempArr: []
    };
  },
  methods: {
    //固定菜单树tree
    fixedTree(bool) {
      if (bool) {
        this.$refs.baseList.$children[0].$children[1].$el.style.paddingLeft =
          "222px";
      } else {
        this.$refs.baseList.$children[0].$children[1].$el.style.paddingLeft =
          "0";
      }
      this.fixedMenu = bool;
      this.cascaderFocusShow = bool;
    },
    //鼠标悬停显示tree菜单
    cascaderMouse() {
      this.cascaderFocusShow = true;
    },
    //页面点击隐藏tree菜单
    pageClick() {
      //判断是否固定菜单
      if (!this.fixedMenu) {
        this.cascaderFocusShow = false;
      }
    },
    getTableData(data) {
      this.tableData = data;

      this.total = data.maxCount;
      this.handleTableChecked(this.tableData); // 恢复有勾选的数据
    },
    rest() {
      this.searchData.phone = "";
      this.searchData.name = "";
      this.curPage = 1;
      let data = {
        page : this.curPage
      }
      this.getList(data);
      
      
    },
    getList(data) {
      if (data) {  
        if (data.communityCode) {
          this.$emit("communityCode", data.communityCode);
          this.tableData = [];
          this.curPage = 1;
          this.pageSize = 10;
          this.treeParams = {
            ...data,
            status: "", //必填
            name: this.searchData.name,
            mobile: this.searchData.phone,
            oemCode: parent.vue.oemInfo.oemCode,
            page: data.page,
            pageSize: data.pageSize,
            operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            orgCode: parent.vue.loginInfo.userinfo.orgCode,
            provinceCode: "",
            cityCode: "",
            countyCode: "",
            streetCode: "",
            houseNo:''
          };
          this.curPage = data.page;
          this.pageSize = data.pageSize;
        }
        if (data.page) {
          this.treeParams.page = data.page;
          this.treeParams.pageSize = data.pageSize;
          this.treeParams.name = this.searchData.name;
          this.treeParams.mobile = this.searchData.phone;
        }
        if (data.cancelable) {
          //点击查询时  查询返回第一页的数据
          this.treeParams.page = 1;
          this.treeParams.name = this.searchData.name;
          this.treeParams.mobile = this.searchData.phone;
          this.curPage = 1;
        }
      } else {
        this.treeParams = {
          status: "", //必填
          name: this.searchData.name,
          mobile: this.searchData.phone,
          oemCode: parent.vue.oemInfo.oemCode,
          page: data.page,
          pageSize: data.pageSize,
          operCustGlobalId: parent.vue.loginInfo.userinfocustGlobalId,
          orgCode: parent.vue.loginInfo.userinfo.orgCode,
          provinceCode: "",
          cityCode: "",
          countyCode: "",
          streetCode: "",
          houseNo:''
        };
      }
       if(data.regionType == '2' || data.regionType == '3'){

            this.treeParams.projectCode = ''
            this.treeParams.buildingCode = data.projectCode

          }
          if(data.regionType == 0 || data.regionType == 4){
            this.treeParams.projectCode = ''
            this.treeParams.houseNo = data.projectCode
          }
      this.$get(
        "/syaa/pc/sy/user/online/home/selectSyFamilyMemberPage",
          this.treeParams
          // pageSize:1000
        
        
      )
        .then(res => {
          if (res.code === "200") {
            // res.dataList.map(item=>{
            //   if(item.status != '2'){
            //     item._disabled = true;
            //   }
            // })
            this.tableData = res.dataList;
            this.getTableData(this.tableData);
            this.total = res.maxCount;
            this.curPage = res.currentPage;
            this.treeParams.name = "";
            this.treeParams.phone = "";
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    select(data) {
      this.$emit("handleSelect", data);
      this.selection = data;
      let tempData = JSON.parse(JSON.stringify(data));
      let reg = /name/gi;
      let temp = JSON.parse(JSON.stringify(tempData).replace(reg, "title"));
      this.sortSelect = temp;
    },
    removeSelect() {
      let temp = this.sortSelect.filter(item => {
        if (!("checked" in item)) {
          return item;
        }
      });
      this.sortSelect = temp;
    },
    removeAll() {
      this.$refs.selection.selectAll(false);
      this.sortSelect = [];
      this.$emit("handleSelect", this.sortSelect);
    },

    currentPage(val) {
      
      //当前页
      this.curPage = val;
      let data = {
        page:this.curPage,
        pageSize:this.pageSize
      }
      this.getList(data);
    },
    hadlePageSize(val) {
      this.pageSize = val;
      let data = {
        page:this.curPage,
        pageSize:this.pageSize
      }
      this.getList(data);
    },

    // ---------------------------分页记忆---------------------------
    /**
     * @param {*} selection 已选项数据
     * @param {*} row 刚选择的项数据
     * @description 勾选table某一项时触发
     */
    onSelect(selection, row) {
      let obj = {
        id: row.id,
        title: row.name,
        custGlobalId: row.custGlobalId
      };
      // this.tempArr.push(row.id) // 将该条数据添加到tempArr中
      this.sortSelect.push(obj); // 将该条数据添加到tempArr中
      this.tableData.forEach(item => {
        // 本页数据中找到该条勾选数据并添加属性_checked = true
        if (item.id === row.id) {
          item["_checked"] = true;
        }
      });
      console.log('list',this.sortSelect);
      
      this.$emit("handleSelect", this.sortSelect);
    },

    /**
     * @param {*} selection 已选项数据
     * @param {*} row 刚取消选择的项数据
     * @description 取消table某一项时触发
     */
    onSelectCancel(selection, row) {
      this.sortSelect.forEach((item, index) => {
        // tempArr中找到该条数据并删除
        if (row.id === item.id) {
          this.sortSelect.splice(index, 1);
        }
      });
      this.$emit("handleSelect", this.sortSelect);
    },

    /**
     * @param {*} selection 已选项数据
     * @description table全选时触发
     */
    onSelectAll(selection) {
      // this.sortSelect = [];
      selection.forEach(item => {
        // 将本页全部勾选添加到tempArr中
        // this.tempArr.push(item.id)
        let obj = {
          id: item.id,
          title: item.name,
          custGlobalId: item.custGlobalId
        };
        this.sortSelect.push(obj);
      });

      this.sortSelect = Array.from(new Set(this.sortSelect)); // 去重
      console.log('去重后的数组',this.sortSelect);
      
      this.$emit("handleSelect", this.sortSelect);
    },

    /**
     * @param {*} selection 已选项数据
     * @description table取消全选时触发
     */
    onSelectAllCancel() {
      this.sortSelect = this.qufei(this.tableData,this.sortSelect)
      this.$emit("handleSelect", this.sortSelect);
    },

    /**
     * @param {*} datas 当前页数据
     * @description 还原有勾选的数据
     */
    handleTableChecked(datas) {
      this.sortSelect.forEach(item => {
        // 判断本页数据状态
        datas.forEach(e => {
          if (item.id === e.id) {
            e["_checked"] = true;
          }
        });
      });
    },
    qufei(array1,array2){
        let result = []
        for(var i = 0; i < array2.length; i++){
            var obj = array2[i];
            var num = obj.id;
            var isExist = false;
            for(var j = 0; j < array1.length; j++){
                var aj = array1[j];
                var n = aj.id;
                if(n == num){
                    isExist = true;
                    break;
                }
            }
            if(!isExist){
                result.push(obj);
            }
        }
        return result
    },
  }
};
</script>

<style lang="less" scoped>
.selectUser {
  .ivu-layout-sider {
    background: #fff;
    min-height: 200px;
    .ivu-layout-sider-children {
      .aside {
        // padding: 20px;
        border: 1px solid rgba(000, 000, 000, 0.1);

        .title {
          padding: 20px;
          border-bottom: 1px solid rgba(000, 000, 000, 0.1);
        }
      }

      background: #fff;
      height: auto;
    }
  }

  .content {
    display: flex;
    height: 720px;
     position: relative;
    .tabList {
      width: 1200px;
      height: 720px;
      padding-top: 10px;
      border-right: 1px solid rgba(000, 000, 000, 0.1);
      overflow: hidden;
     .top{
       height: 40px;
     }
     .bottom{
       overflow-y: scroll;
       height: 640px;
       padding-bottom: 20px;
     }
      .ivu-table-wrapper {
        min-height: 653px;
      }
      .page{
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    .buttonList {
      width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 500px;
      height: 100%;
    }

    .selectList {
      width: 260px;
      overflow-y: scroll;
      height: 690;

      border-radius: 0px;
      // border-top: none;
      // border-bottom: none;
      border: 1px solid rgba(000, 000, 000, 0.1);

      .ivu-checkbox-group-item {
        display: block;
        margin: 20px 50px;
      }
    }
  }
  /deep/.ivu-tooltip-dark {
    display: none;
  }
  /deep/#modal_contentarea{
    overflow: visible !important;
  }
}
</style>
